<template>
    <SendCheckOrderManage />
</template>

<script>
import { defineComponent } from "vue";
import SendCheckOrderManage from "../../qualityManage/sendCheckOrderManage/materialSendCheckList/index.vue";

export default defineComponent({
    components: {
        SendCheckOrderManage,
    },
});
</script>
